import React, { Component } from "react"
import Helmet from 'react-helmet'; 

// Common components & Layout 
import Layout from "../../layouts"; 
import { Container } from "react-bootstrap";

// Individual components that make up the page
import Newsletter from "../../components/newsletter/newsletter";
import Hero from "../../components/common/Hero"; 
import SEO from "../../components/common/SEO";
import { SectionHeader } from "../../components/common/section-header";   
import Section from "../../components/common/Section"; 

class Success extends Component {
  render() {
      return (
        <Layout>
          <Helmet bodyAttributes={{ class: "contact-page" }} />
          <SEO />
          <Hero title="Contact Us" color="blue"></Hero>
          <Container style={{ padding: '10px'}}>
            <section id="contact_us">
              <Section name="Contact Us" header="contact_us" /> 
              <div class="container">
                  <h2>Thank you for contacting us we will respond as soon as we can.</h2>
              </div>
            </section>
          </Container>
          <Newsletter />
        </Layout>
      ); 
  }
}

export default Success; 